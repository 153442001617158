import type { BoardMemberOptionsType } from '#board/types'
import { BOARD_MEMBER_OPTIONS_FRAGMENT, BOARD_BASE_FRAGMENT } from '#board/fragment'
import { BOARD_TYPE_NAME } from '#board/constant'
import { WORKSPACE_LIST_BOARDS_QUERY, USER_BOARDS_QUERY } from '#workspace/schema'

export const useBoardMemberOptionFragment = (boardMemberId: string) => {
  const { client } = useApolloClient()
  const options = {
    id: `BoardMemberOptionsType:${boardMemberId}`,
    fragment: BOARD_MEMBER_OPTIONS_FRAGMENT,
  }
  const read = () => {
    return client.readFragment<BoardMemberOptionsType>(options, true)
  }

  return {
    read,
  }
}

export const readBoardFromCache = (boardId: string) => {
  const { client } = useApolloClient()
  return client.readFragment({
    id: getIdentifier(boardId, BOARD_TYPE_NAME),
    fragment: BOARD_BASE_FRAGMENT
  })
}

export const readListBoardsFromCache = (workspaceId: string) => {
  const { client } = useApolloClient()
  return client.readQuery({
    query: WORKSPACE_LIST_BOARDS_QUERY,
    variables: {
      filter: {
        workspaceId,
      },
      workspaceId,
    },
  })
}

export const readMostRecentBoardFromCache = (workspaceId: string) => {
  const { client } = useApolloClient()
  const result = client.readQuery({
    query: USER_BOARDS_QUERY,
    variables: {
      workspaceId
    }
  })

  return result.memberBoards[0].board
}
